import React from 'react';
import './Loader.css';  
import { useTranslation } from 'react-i18next'


const Loader = () => {
        const { t, i18n } = useTranslation()
    
  return (
    <div className="vetri-loader-container">
      <div className="vetri-loader"></div>
      <div className="vetri-text">{t('தமிழக வெற்றிக் கழகம்')}</div>
    </div>
  );
};

export default Loader;
