import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Common from './Helper/Common';
import Loader from './Loader/Loader';
import Auth from './Auth';
import Header from './Component/Common/Header/Header';
import Footer from './Component/Common/Footer/Footer';
import Home from './Component/Home/Home';
import Otp from './Component/Otp/Otp';
import Sidebarmenu from './Component/Sidebar/Sidebarmenu'

const Addprofile = React.lazy(() => import('./Component/Addimage/Addprofile'))
const Membercard = React.lazy(() => import('./Component/Membercard/Membercard'))
const Joinmember = React.lazy(() => import('./Component/Joinmember/Joinmember'))
const Userdashboard = React.lazy(() => import('./Component/Userdashboard/Userdashboard'))
const Conference = React.lazy(() => import('./Component/Conference/Confierence'))
const Login = React.lazy(() => import('./Component/Common/Login'))
const Register = React.lazy(() => import('./Component/Common/Register'))
const History = React.lazy(()=>import('./Component/History/History'))
const Commonevents = React.lazy(()=>import('./Component/Comomonevents/Commonevents'))
const Poster = React.lazy(()=>import('./Component/Poster/Poster'))
const Administrators = React.lazy(()=>import('./Component/Administrators/Administrators'))
const Organizationmember = React.lazy(()=>import('./Component/Organizationmember/Organizationmember'))
const Education = React.lazy(()=>import('./Component/Education/Education'))
const Organization = React.lazy(()=>import('./Component/Organization/Organization'))
const Gallery = React.lazy(()=>import('./Component/Gallery/Gallery'))
const Manifesto = React.lazy(()=>import('./Component/Manifesto/Manifesto'))
const Media = React.lazy(()=>import('./Component/Media/Media'))
const Events = React.lazy(()=>import('./Component/Events/Events'))
const Achievements = React.lazy(()=>import('./Component/Achievements/Achievements'))
const Pledge = React.lazy(()=>import('./Component/Pledge/Pledge'))
const Network = React.lazy(()=>import('./Component/Network/Network'))
const Principle = React.lazy(()=>import('./Component/Principle/Principle'))



function MainIndex() {
    const location = useLocation();
    // const [playedBefore, setPlayedBefore] = useState(false);
    // const [play, { stop }] = useSound('https://res.cloudinary.com/ddgcnuz4k/video/upload/v1713250209/vaa-mudhalvaa-time-to-rule-thalaivar-vijay-tvk-anthem-tamilaga-vettri-k_mgpxSt0F_nzhmxi.mp3', { interrupt: true });

    // useEffect(() => {

    //     if (location.pathname === '/history' && !playedBefore) {
    //         play();
    //         setPlayedBefore(true); 
    //     } else if (location.pathname !== '/history') {
    //         stop();
    //     }
    // }, [location.pathname, playedBefore, play, stop]);

    const hideFooterRoutes = ['/login', '/register'];


    return (
        <Suspense fallback={<Loader/>}>
            <Header />
            <div>
                <Routes>
                    <Route>
                        <Route path='*' exact={true} element={<Navigate to="/" />} />
                        <Route path='/' exact={true} element={<Navigate to="/register" />} />

                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />


                    </Route>
                    <Route element={<Auth />}>
                        <Route path="/home" element={<Home />} />
                        <Route path="/principle" element={<Principle />} />
                        <Route path="/network" element={<Network />} />
                        <Route path="/pledge" element={<Pledge />} />
                        <Route path="/Achievements" element={<Achievements />} />
                        <Route path="/Events" element={<Events />} />
                        <Route path="/Media" element={<Media />} />
                        <Route path="/announcement" element={<Manifesto />} />
                        <Route path="/Gallery" element={<Gallery />} />
                        <Route path="/Education" element={<Education />} />
                        <Route path="/Organization" element={<Organization />} />
                        <Route path="/Organizationmember" element={<Organizationmember />} />
                        <Route path="/Administrators" element={<Administrators />} />
                        <Route path="/Poster" element={<Poster />} />
                        <Route path="/commonevents" element={<Commonevents />} />
                        <Route path="/conference" element={<Conference />} />
                        <Route path="/history" element={<History />} />
                        <Route path="/addpost" element={<Addprofile />} />
                        <Route path="/Joinmember" element={<Joinmember />} />
                        <Route path="/membercard" element={<Membercard />} />
                        <Route path="/userdashboard" element={<Userdashboard />} />
                    </Route>
                </Routes>
            </div>
            <Footer />
            {/* {!hideFooterRoutes.includes(location.pathname) && } */}
        </Suspense>
    );
}

export default MainIndex;
